import { Modal } from 'ant-design-vue';
import store from '@/store';

const setCloudAKSKMixin = {
  data() {
    return {
      akskModalConfig: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
      },
      akskForm: {
        ak: '',
        sk: ''
      },
      akskFormRules: {
        ak: [
          {
            required: true,
            message: this.$t('qing-shu-ru-ak'),
            trigger: 'blur'
          }
        ],
        sk: [
          {
            required: true,
            message: this.$t('qing-shu-ru-sk'),
            trigger: 'blur'
          }
        ]
      },
      akskModalErrMsg: {
        ak: '',
        sk: ''
      }
    };
  },
  methods: {
    handleBlurCloudAkskModalValidate(type) {
      if (!this.akskForm[type]) {
        this.akskModalErrMsg[type] = this.$t('thisadddatasourceformconnecttype-bu-neng-wei-kong', [type]);
      } else {
        this.akskModalErrMsg[type] = '';
      }
    },
    setCloudAKSKModal() {
      const handleAuthorize = async () => {
        const {
          ak,
          sk
        } = this.akskForm;
        if (this.akskModalConfig.ak) {
          this.akskModalErrMsg.ak = this.$t('ak-bu-neng-wei-kong');
        }
        if (this.akskModalConfig.sk) {
          this.akskModalErrMsg.sk = this.$t('sk-bu-neng-wei-kong');
        }
        if (this.akskModalErrMsg.ak || this.akskModalErrMsg.sk) {
          return false;
        }

        const res = await this.$services.updatealiyunaksk({
          data: {
            aliyunAk: ak,
            aliyunSk: sk
          },
          msg: this.$t('shou-quan-cheng-gong')
        });

        if (res.success) {
          hideModal();
        }
      };
      const handleRemoveAuthorize = async () => {
        const res = await this.$services.cleanaliyunaksk({
          data: {},
          msg: this.$t('jie-chu-shou-quan-cheng-gong')
        });

        if (res.success) {
          this.$store.dispatch('getUserInfo');
          modal.destroy();
        }
      };
      const modal = Modal.info({
        title: `${this.$t('a-li-yun-fang-wen-quan-xian')}(${this.userInfo.aliyunAkSkSet ? this.$t('yi-she-zhi-0') : this.$t('wei-she-zhi')})`,
        width: 620,
        class: 'cloud-aksk-modal',
        closable: true,
        centered: true,
        content: () => (
          <div>
            <a-alert type="warning">
              <p slot="message">
                { this.$t('dang-shi-yong-clouddm-duiali-yun-yun-shu-ju-ku-xiao-xi-huo-zhe-da-shu-ju-chan-pin-jin-hang-shu-ju-cao-zuo-he-guan-li-shi-clouddm-hui-shi-yong-yong-hu-ti-gong-deali-yun-zi-zhang-hao-de-accesskey-he-secretkey-jin-hang-bi-yao-de-zi-yuan-xin-xi-fang-wen') }
                <a href={ `${store.state.docUrlPrefix}/cc-doc/reference/rds_mysql_ram_least_privilege` } target="_blank">{ this.$t('ru-he-shen-qingali-yun-fang-wen-quan-xian') }</a>
              </p>
            </a-alert>
            <a-form-model label-col={this.akskModalConfig.labelCol}
                          wrapper-col={this.akskModalConfig.wrapperCol}
                          style={{ marginTop: '20px' }}
            >
              <a-form-model-item label="AccessKey ID">
                <a-input v-model={this.akskForm.ak}
                         onBlur={() => this.handleBlurCloudAkskModalValidate('ak')}/>
                <div class='error-msg'>{this.akskModalErrMsg.ak}</div>
              </a-form-model-item>
              <a-form-model-item label="AccessKey Secret">
                <a-input v-model={this.akskForm.sk}
                         onBlur={() => this.handleBlurCloudAkskModalValidate('sk')}/>
                <div class='error-msg'>{this.akskModalErrMsg.sk}</div>
              </a-form-model-item>
            </a-form-model>
            <div class='footer'>
              <a-button type="primary" onclick={() => handleAuthorize()}>{ this.$t('shou-quan-fang-wen') }</a-button>
              {this.userInfo.aliyunAkSkSet ? <a-button onclick={() => handleRemoveAuthorize()}
                                                       style={{ marginRight: '16px' }}>{ this.$t('jie-chu-fang-wen-quan-xian') }
              </a-button> : null}
              <a-button onClick={() => hideModal()}>
                { this.$t('qu-xiao') }
              </a-button>
            </div>
          </div>
        )
      });

      const hideModal = () => {
        this.akskForm = {
          ak: '',
          sk: ''
        };
        this.akskModalErrMsg = {
          ak: '',
          sk: ''
        };
        modal.destroy();
      };
    }
  }
};

export default setCloudAKSKMixin;
