<template>
  <div class="login" v-if="!globalSettings.personalDesktopVersion">
    <header>
      <cc-logo-header/>
    </header>
    <div class="content">
      <div :class="`has-background ${includesCC && includesDM ? 'rdp-background' : includesCC ?'cc-background':'dm-background'}`">
        <div class="tabs">
          <a-tabs v-model="loginForm.accountType" @change="handleTabChange">
            <a-tab-pane :key="ACCOUNT_TYPE.PRIMARY_ACCOUNT" :tab="$t('zhu-zhang-hao-deng-lu')">
            </a-tab-pane>
            <a-tab-pane :key="ACCOUNT_TYPE.SUB_ACCOUNT"
                        :tab="globalSettings.features.SUPPORT_LDAP ? 'LDAP' : $t('zi-zhang-hao-deng-lu')"></a-tab-pane>
          </a-tabs>
          <div class="tabs-content">
            <div class="msg" v-if="errMsg">
              <a-alert :message="errMsg" banner type="error"/>
            </div>
            <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;"
                 v-if="loginForm.accountType === ACCOUNT_TYPE.PRIMARY_ACCOUNT">
              <div style="font-size: 14px;">
                {{ loginForm.loginType === LOGIN_TYPE.LOGIN_PASSWORD ? $t('zhang-hao-mi-ma-deng-lu') : $t('duan-xin-yan-zheng-ma-deng-lu') }}
              </div>
              <div style="cursor: pointer;display: flex;" @click="handleChangeLoginType">
                <div class="tooltip">{{
                    loginForm.loginType === LOGIN_TYPE.LOGIN_PASSWORD ? $t('duan-xin-yan-zheng-ma-deng-lu') : $t('zhang-hao-mi-ma-deng-lu')
                  }}
                </div>
                <img v-if="loginForm.loginType === LOGIN_TYPE.LOGIN_PASSWORD" :height="30" :width="30" alt=""
                     src="../../assets/mes.png">
                <img v-else :height="30" :width="30" alt="" src="../../assets/pass.png">
                <!--                <cc-iconfont :name="loginForm.loginType === LOGIN_TYPE.LOGIN_PASSWORD ? 'login-pwd' : 'login-verify'"-->
                <!--                             :size="30"/>-->
              </div>
            </div>
            <div class="input-wrapper">
              <a-select v-model="loginForm.primaryUid" :placeholder="$t('xuan-ze-zu-zhi')" style="width: 360px;" size="large"
                        v-if="globalSettings.features.SUPPORT_LDAP && loginForm.accountType === ACCOUNT_TYPE.SUB_ACCOUNT"
                        show-search
                        :filter-option="filterOption"
              >
                <a-select-option v-for="org in orgs" :value="org.uid" :key="org.uid">{{ org.company }}</a-select-option>
              </a-select>
              <div style="display: flex; align-items: center;">
                <a-input v-model="loginForm.account" v-if="loginForm.accountType===ACCOUNT_TYPE.PRIMARY_ACCOUNT || globalSettings.features.SUPPORT_LDAP || !isPrivate"
                         :placeholder="loginForm.accountType===ACCOUNT_TYPE.PRIMARY_ACCOUNT ? loginForm.loginType === LOGIN_TYPE.LOGIN_VERIFY ? $t('shou-ji') : $t('shou-ji-you-xiang-di-zhi') :
                         globalSettings.features.SUPPORT_LDAP ? $t('ldap-zhang-hao') : $t('zi-zhang-hao')"
                         size="large">
                </a-input>
                <a-input-group compact v-if="isPrivate && loginForm.accountType===ACCOUNT_TYPE.SUB_ACCOUNT && !globalSettings.features.SUPPORT_LDAP && primaryUserDomainList.length > 1">
                  <a-input v-model="loginForm.account" style="width: 110px"
                           :placeholder="loginForm.accountType===ACCOUNT_TYPE.PRIMARY_ACCOUNT ? loginForm.loginType === LOGIN_TYPE.LOGIN_VERIFY ? $t('shou-ji') : $t('shou-ji-you-xiang-di-zhi') :
                         globalSettings.features.SUPPORT_LDAP ? $t('ldap-zhang-hao') : $t('zi-zhang-hao')"
                           size="large">
                  </a-input>
                  <a-select v-model="selectDomain" size="large" style="width:250px;border-left:none">
                    <a-select-option v-for="domain in primaryUserDomainList" :key="domain" :value="domain">{{domain}}</a-select-option>
                  </a-select>
                </a-input-group>
                <a-input v-model="loginForm.account" v-if="isPrivate && loginForm.accountType===ACCOUNT_TYPE.SUB_ACCOUNT && !globalSettings.features.SUPPORT_LDAP && primaryUserDomainList.length <= 1"
                         :placeholder="loginForm.accountType===ACCOUNT_TYPE.PRIMARY_ACCOUNT ? loginForm.loginType === LOGIN_TYPE.LOGIN_VERIFY ? $t('shou-ji') : $t('shou-ji-you-xiang-di-zhi') :
                         globalSettings.features.SUPPORT_LDAP ? $t('ldap-zhang-hao') : $t('zi-zhang-hao')" :addon-after="selectDomain"
                         size="large">
                </a-input>
                <cc-sms-button v-if="loginForm.loginType === LOGIN_TYPE.LOGIN_VERIFY"
                               :phone-number="loginForm.account" size="large" style="width: 120px;margin: 0 0 0 10px;"
                               verify-code-type="LOGIN" verify-type="SMS_VERIFY_CODE"/>
              </div>

              <a-input v-if="loginForm.loginType === LOGIN_TYPE.LOGIN_VERIFY" v-model="loginForm.verifyCode"
                       :placeholder="$t('yan-zheng-ma')" size="large"/>
              <cc-password-input v-else v-model="loginForm.password"
                                 :handleEnter="handleEnter"
                                 size="large"/>
            </div>
            <a-button :disabled="loginLoading" :loading="loginLoading" size="large"
                      style="margin-top: 20px;width: 100%;"
                      type="primary"
                      @click="handleLogin">{{ $t('deng-lu') }}
            </a-button>
            <div class="login-operation">
              <div v-if="globalSettings.features.ENABLE_REGISTER">{{ $t('mei-you-zhang-hao-qu') }}<span style="margin-left: 4px" @click="goRegister">{{ $t('zhu-ce') }}</span></div>
              <div @click="goResetPassword">{{ $t('wang-ji-mi-ma') }}</div>
            </div>
          </div>
          <a-modal v-model="showAccountInformationCompletionModal" :title="$t('zhang-hao-xin-xi-bu-quan')" :mask-closable="false" :width="374">
            <a-form-model :model="completeForm" :rules="rules" :label-col="{span:4}" :wrapper-col="{span:16}" ref="completeModalRef">
              <a-form-model-item :label="$t('you-xiang')" prop="email">
                <a-input v-model="completeForm.email" style="width: 280px;"/>
              </a-form-model-item>
              <a-form-model-item :label="$t('shou-ji-hao')" prop="phone">
                <a-input v-model="completeForm.phone" style="width: 280px;"/>
              </a-form-model-item>
            </a-form-model>
            <div class="footer">
              <a-button type="primary" @click="handleComplete">{{ $t('bao-cun') }}</a-button>
              <a-button @click="hideCompleteModal">{{ $t('qu-xiao') }}</a-button>
            </div>
          </a-modal>
        </div>
      </div>
    </div>
    <footer>
      <cc-footer/>
    </footer>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import CCFooter from '@/components/CCFooter';
import CCLogoHeader from '@/components/CCLogoHeader';
import { ACCOUNT_TYPE, LOGIN_TYPE } from '@/const';
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { UPDATE_GLOBAL_SETTING } from '@/store/mutationTypes';
import { primaryUserDomains } from '@/services/cc/api/auth';

export default {
  name: 'Login',
  components: {
    CcLogoHeader: CCLogoHeader,
    CcFooter: CCFooter
  },
  computed: {
    ...mapState(['defaultRedirectUrl']),
    ...mapGetters([
      'includesCC',
      'includesDM'
    ])
  },
  data() {
    const validateSubaccount = async (checkType, checkContent, callback) => {
      console.log(checkContent, checkType.toLowerCase(), this.preCompleteForm[checkType.toLowerCase()]);
      if (checkContent === this.preCompleteForm[checkType.toLowerCase()]) {
        callback();
      } else {
        const res = await this.$services.checkSupplement({
          data: {
            checkType,
            checkContent,
            primaryUid: this.loginedForm.primaryUid
          },
          modal: false
        });
        if (res.success) {
          callback();
        } else {
          callback(new Error(res.msg));
        }
      }
    };
    const validateSubaccountPhone = (rule, value, callback) => {
      validateSubaccount('PHONE', value, callback);
    };
    const validateSubaccountEmail = (rule, value, callback) => {
      validateSubaccount('EMAIL', value, callback);
    };
    return {
      showAccountInformationCompletionModal: false,
      ACCOUNT_TYPE,
      LOGIN_TYPE,
      loginedForm: {},
      loginForm: {
        accountType: ACCOUNT_TYPE.PRIMARY_ACCOUNT,
        loginType: LOGIN_TYPE.LOGIN_PASSWORD,
        primaryUid: undefined,
        account: '',
        password: '',
        verifyCode: ''
      },
      completeForm: {
        email: '',
        phone: ''
      },
      primaryUserDomainList: [],
      selectDomain: '',
      isPrivate: false,
      preCompleteForm: {},
      errMsg: '',
      loginLoading: false,
      globalSettings: {
        personalDesktopVersion: true
      },
      orgs: [],
      rules: {
        email: [
          { required: true, trigger: 'blur', message: this.$t('you-xiang-bu-neng-wei-kong') },
          { validator: validateSubaccountEmail, trigger: 'blur' }
        ],
        phone: [
          { required: true, trigger: 'blur', message: this.$t('shou-ji-hao-bu-neng-wei-kong') },
          { validator: validateSubaccountPhone, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    async handleComplete() {
      this.$refs.completeModalRef.validate(async (valid) => {
        if (valid) {
          const res = await this.$services.login({
            data: {
              ...this.loginedForm,
              registerInfo: this.completeForm,
              loginType: this.globalSettings.features.SUPPORT_LDAP && this.loginForm.accountType === ACCOUNT_TYPE.SUB_ACCOUNT ? LOGIN_TYPE.LOGIN_LDAP : this.loginForm.loginType
            }
          });
          if (res.success) {
            await this.$router.push({ name: 'Home' });
            this.hideCompleteModal();
          }
        }
      });
    },
    hideCompleteModal() {
      this.showAccountInformationCompletionModal = false;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    handleTabChange() {
      this.errMsg = '';
      Vue.set(this.loginForm, 'loginType', LOGIN_TYPE.LOGIN_PASSWORD);
    },
    handleChangeLoginType() {
      const loginType = this.loginForm.loginType === LOGIN_TYPE.LOGIN_VERIFY ? LOGIN_TYPE.LOGIN_PASSWORD : LOGIN_TYPE.LOGIN_VERIFY;
      Vue.set(this.loginForm, 'loginType', loginType);
    },
    async handleLogin() {
      this.loginLoading = true;
      const data = {
        ...this.loginForm,
        account: this.loginForm.accountType === ACCOUNT_TYPE.SUB_ACCOUNT ? this.loginForm.account + this.selectDomain : this.loginForm.account,
        loginType: this.globalSettings.features.SUPPORT_LDAP && this.loginForm.accountType === ACCOUNT_TYPE.SUB_ACCOUNT ? LOGIN_TYPE.LOGIN_LDAP : this.loginForm.loginType
      };
      this.loginedForm = data;
      const res = await this.$services.login({
        data
      });
      if (res.success) {
        this.errMsg = '';
        await this.$store.dispatch('getUserInfo');
        if (res.data.needMore) {
          this.showAccountInformationCompletionModal = true;
          this.completeForm = res.data.moreInfo;
          this.preCompleteForm = cloneDeep(res.data.moreInfo);
        } else {
          await this.$router.push(this.defaultRedirectUrl);
        }
      } else {
        this.errMsg = res.msg;
      }
      this.loginLoading = false;
    },
    goRegister() {
      this.$router.push({ name: 'Register' });
    },
    goResetPassword() {
      this.$router.push({ name: 'Reset' });
    },
    handleEnter(arg) {
      if (arg.keyCode === 13) {
        this.handleLogin();
      }
    },
    async getGlobalSettings() {
      const res = await this.$services.getGlobalSettings({ data: {} });

      if (res.success) {
        this.globalSettings = res.data;
        this.$store.commit(UPDATE_GLOBAL_SETTING, res.data);
        if (this.globalSettings.personalDesktopVersion) {
          this.loginForm.account = this.globalSettings.personalDesktopVersionAccount;
          this.loginForm.password = this.globalSettings.personalDesktopVersionPassword;
          this.$store.commit('getDeploySite', res.data.deploySite);
          await this.handleLogin();
        }
        if (this.globalSettings.deployMode === 'CLOUD_PRIVATE') {
          this.isPrivate = true;
          if (this.isPrivate) {
            primaryUserDomains().then((res1) => {
              if (res1.data.success) {
                this.primaryUserDomainList = res1.data.data;
                this.primaryUserDomainList.forEach((domain, index) => {
                  this.primaryUserDomainList[index] = `@${domain}`;
                });
                this.selectDomain = this.primaryUserDomainList[0];
              }
            });
          }
        }
      }
    },
    async listOrg() {
      const res = await this.$services.listOrg({ data: {} });

      if (res.success) {
        this.orgs = res.data;
      }
    }
  },
  created() {
    this.getGlobalSettings();
    this.listOrg();
  }
};
</script>

<style lang="less" scoped>
.login {
  header {
    .width-full();
  }

  .content {
    width: 100%;
    .width-full();
    position: absolute;
    top: 80px;
    //bottom: 56px;
    background-color: #C3D8E9;
    overflow: scroll;
    height: 545px;
    //padding: 0 105px;

    .has-background {
      //width: 100%;
      height: 100%;
      width: 1200px;
      margin: 0 auto;
    }

    .rdp-background {
      background: url("../../assets/bg-rdp.png") no-repeat 0 80px;
      background-size: 620px;
    }

    .cc-background {
      background: url("../../assets/logo/loginBg.png") no-repeat 0 60px;
      background-size: 480px;
    }

    .dm-background {
      background: url("../../assets/loginBack.png") no-repeat 0 60px;
      background-size: 580px;
    }

    .tabs {
      width: 520px;
      margin-top: 60px;
      background: white;
      float: right;

      /deep/ .ant-tabs {
        .ant-tabs-bar {
          //border-bottom: 2px solid #ECECEC;
        }

        .ant-tabs-nav {
          height: 64px;
          margin-left: 82px;

          .ant-tabs-tab {
            line-height: 48px;
            font-size: 16px;
            color: #333333;
          }

          .ant-tabs-ink-bar {
            background: #0BB9F8;
          }

          .ant-tabs-tab-active {
            font-weight: 400;
          }
        }
      }

      .tabs-content {
        padding: 20px 80px 48px 80px;
        position: relative;

        .msg {
          position: relative;
          bottom: 10px;
        }

        .input-wrapper {
          & > div {
            margin-bottom: 20px;
          }
        }

        .ant-btn {
          width: 360px;
          margin-top: 8px;
          margin-bottom: 20px;
          font-size: 16px;

          span {
            font-size: 16px;
          }
        }
      }
      .login-operation {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        //margin-top: 20px;

        div:last-child,
        div > span {
          cursor: pointer;
        }

        div, span {
          font-size: 14px;
        }

        span {
          color: @primary-color;
        }
      }
    }
  }

  .tooltip {
    position: relative;
    width: 105px;
    height: 30px;
    border: 1px solid #FFC441;
    color: #FF6E0D;
    background-color: #FFF7DB;
    margin-right: 12px;
    line-height: 30px;
    text-align: center;
  }

  .tooltip:before {
    content: '';
    display: block;
    position: absolute;
    right: -10px;
    top: 5px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #FFC441;
  }

  .tooltip:after {
    content: '';
    display: block;
    position: absolute;
    right: -9px;
    top: 5px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #FFF7DB;
  }

  footer {
    .width-full();
    //position: absolute;
    //bottom: 0;
    margin-top: 542px;
  }
}

</style>
